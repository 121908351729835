import { useMutation } from '@moonpig/web-core-graphql'
import { AddCardAndDigitalGiftToBasketGQL } from '../../queries/addDigitalGiftToBasket/AddCardAndDigitalGiftToBasket'
import {
  addCardAndDigitalGiftToBasketMutation as addCardAndDigitalGiftToBasketType,
  addCardAndDigitalGiftToBasketMutationVariables,
} from '../../queries/addDigitalGiftToBasket/__generated__/AddCardAndDigitalGiftToBasket'

export const useAddCardAndDigitalGiftToBasketMutation = () => {
  const [addCardAndDigitalGiftToBasketMutation] = useMutation<
    addCardAndDigitalGiftToBasketType,
    addCardAndDigitalGiftToBasketMutationVariables
  >(AddCardAndDigitalGiftToBasketGQL)

  const addCardAndDigitalGiftToBasket = async ({
    designId,
    cardSku,
    digitalGiftSku,
  }: {
    designId: string
    cardSku: string
    digitalGiftSku: string
  }): Promise<{ id: string; totalItems: number }> => {
    const { data } = await addCardAndDigitalGiftToBasketMutation({
      variables: { input: { designId, cardSku, digitalGiftSku } },
    })

    if (data?.addCardAndDigitalGiftToBasket?.__typename !== 'Basket') {
      throw new Error('Adding card and digital gift to basket failed')
    }

    const totalItems = data.addCardAndDigitalGiftToBasket.totalItems
    const id = data.addCardAndDigitalGiftToBasket.id

    return {
      id,
      totalItems,
    }
  }

  return { addCardAndDigitalGiftToBasket }
}
