import { useMutation } from '@moonpig/web-core-graphql'
import {
  trackGAEvent,
  createBasketEvent,
  trackUIEvent,
  createCustomisationCompletedEvent,
} from '@moonpig/web-core-analytics'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { Region } from '@moonpig/web-core-types'
import { getDeviceType } from '@moonpig/web-shared-utils'
import { MasterVariant, LineItem } from './types'
import { addDigitalGiftCardToBasket } from '../../queries/addDigitalGiftToBasket/addDigitalGiftCardToBasket'
import { addDigitalGiftToBasket_addDigitalGiftToBasket_Basket } from '../types-graphql'

export const useAddDigitalGiftToBasket = () => {
  const [addDigitalGiftToBasketMutation] = useMutation(
    addDigitalGiftCardToBasket,
  )

  const addToBasket = async ({
    productMasterVariant,
    lastLineItem,
    basketId,
    region,
    customerId,
  }: {
    productMasterVariant: MasterVariant
    lastLineItem?: Pick<LineItem, 'id' | 'productType'> | null
    basketId: string
    region: Region
    customerId?: string | undefined
  }): Promise<{
    addToBasketData: addDigitalGiftToBasket_addDigitalGiftToBasket_Basket
  }> => {
    if (!productMasterVariant.sku || !lastLineItem) {
      throw Error('Add digital gift to basket validation failed')
    }

    if (lastLineItem?.productType !== 'greetingcard') {
      throw Error(
        'Add digital gift to basket failed as lastLineItem is not a card',
      )
    }

    const { data } = await addDigitalGiftToBasketMutation({
      variables: {
        input: {
          sku: productMasterVariant.sku,
          parentLineItemId: lastLineItem.id,
          id: basketId,
        },
      },
    })

    if (
      data?.addDigitalGiftToBasket === null ||
      data?.addDigitalGiftToBasket?.__typename !== 'Basket'
    ) {
      const errorMessage = `${data?.addDigitalGiftToBasket?.__typename}: ${data?.addDigitalGiftToBasket?.message}`
      throw Error(errorMessage)
    }

    const { mnpg_ui_events_api_correlation_id: correlationId } =
      getBrowserCookies()

    trackUIEvent(
      createCustomisationCompletedEvent({
        correlationId,
        productId: productMasterVariant.sku.split('-')[0],
        store: region.toUpperCase(),
        source: getDeviceType(),
        customerId,
      }),
    )

    return { addToBasketData: data.addDigitalGiftToBasket }
  }

  const trackAddDigitalGiftToBasket = (
    masterVariant: MasterVariant,
    totalBasketSize: number,
    basketId: string,
  ) => {
    const price = masterVariant.price.centAmount / 100
    const formattedPrice = price.toFixed(2)
    const sku = masterVariant.sku?.toLowerCase()

    const item = {
      item_brand: 'moonpig',
      item_name: masterVariant.title.toLocaleLowerCase(),
      item_id: sku ?? /* istanbul ignore next */ '',
      item_variant: undefined,
      item_category: DepartmentsEnum.DIGITAL_GIFTS.toLocaleLowerCase().replace(
        '_',
        ' ',
      ),
      item_category2: 'all_gifts',
      item_category3: 'in-stock',
      item_category4: 'non-group-card',
      item_category5: undefined,
      item_list_name: undefined,
      index: undefined,
      quantity: 1,
      price,
    }

    trackGAEvent(
      createBasketEvent({
        event: 'add_to_cart',
        products: [item],
        basket: {
          size: totalBasketSize,
          action: 'add to cart',
          id: basketId.toLowerCase(),
        },
        action: 'add to basket',
        currency: masterVariant.price.currencyCode.toLocaleLowerCase(),
        totalValue: Number(formattedPrice),
        label: `digital gift | ${sku}`,
        productIndex: undefined,
        eventCategory: 'product action',
      }),
    )
  }

  const addDigitalGiftToBasket = async ({
    lastLineItem,
    productMasterVariant,
    basketId,
    region,
    customerId,
  }: {
    productMasterVariant: MasterVariant
    basketId: string
    lastLineItem?: Pick<LineItem, 'id' | 'productType'> | null
    region: Region
    customerId?: string | undefined
  }) => {
    const { addToBasketData } = await addToBasket({
      productMasterVariant,
      lastLineItem,
      basketId,
      region,
      customerId,
    })

    trackAddDigitalGiftToBasket(
      productMasterVariant,
      addToBasketData.totalItems,
      basketId,
    )

    return { data: addToBasketData }
  }

  return {
    addDigitalGiftToBasket,
  }
}
