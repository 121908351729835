import React from 'react'
import {
  Image,
  Link,
  Box,
  Pill,
  HeartToggle,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from '../ProductTile.locale'
import { HeartIcon } from '../HeartIcon'
import { PillVariant } from '../../../types'
import { SponsoredPill } from '../../SponsoredPill'

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  ${s({ boxShadow: 2 })}
  transition: all 200ms ease-in-out;
  ${breakpointUp('md')} {
    &:hover {
      ${s({ boxShadow: 4 })}
    }
  }
`

const StyledWrapper = styled.div`
  position: relative;

  &.not-portrait {
    display: flex;
    height: 100%;
    align-items: flex-end;
  }
`

const StyledImageWrapper = styled.div`
  transform: translateY(0);
  transition: all 200ms ease-in-out;
  ${breakpointUp('md')} {
    &:hover {
      ${({ theme }) => `transform: translateY(-${theme.spacing[3]}px);`}
    }
  }
`

const StyledOurPickWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
`

const StyledHeartToggleWrapper = styled.div`
  ${s({
    position: 'absolute',
    zIndex: 1,
    bottom: 4,
    right: 4,
  })}
`

type CardProductTileProps = {
  href: string
  image: {
    src: string
    alt: string
  }
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  ref?: React.Ref<HTMLAnchorElement>
  /** Determines the card image aspect ratio */
  portraitCardAspectRatio?: number
  /** Determines the favourite toggle state props */
  favourite?: {
    /** State of the favourite toggle */
    isSelected?: boolean
    /** Callback function to change the state of the favourite toggle */
    onSelect: (e: React.MouseEvent<HTMLButtonElement>) => void
  }
  ourPick?: boolean
  sponsored?: boolean
  label?: string
  variant?: PillVariant
  icon?: React.ReactElement
}

export const CardProductTile = React.forwardRef(
  (props: CardProductTileProps, ref?: React.Ref<HTMLAnchorElement>) => {
    const {
      href,
      image,
      onClick,
      portraitCardAspectRatio,
      favourite,
      ourPick,
      sponsored,
      label,
      variant,
      icon,
    } = props
    const localiseText = useLocaleText()

    return (
      <StyledWrapper
        className={`${!portraitCardAspectRatio ? 'not-portrait' : ''}`}
      >
        <Box position="relative">
          {favourite && (
            <StyledHeartToggleWrapper>
              <HeartToggle
                isToggled={!!favourite.isSelected}
                onToggle={(e: React.MouseEvent<HTMLButtonElement>) =>
                  favourite.onSelect(e)
                }
                ariaLabel={
                  favourite.isSelected
                    ? `${localiseText(
                        'card_product_tile.remove_from_favourites',
                      )}`
                    : `${localiseText('card_product_tile.add_to_favourites')}`
                }
              />
            </StyledHeartToggleWrapper>
          )}
          <StyledImageWrapper>
            <Link
              href={href}
              onClick={onClick}
              ref={ref}
              data-testid="shared-products-card-product-tile"
            >
              {sponsored && (
                <StyledOurPickWrapper>
                  <SponsoredPill />
                </StyledOurPickWrapper>
              )}

              {!sponsored && ourPick && (
                <StyledOurPickWrapper>
                  <Pill label={localiseText('our_pick')} icon={<HeartIcon />} />
                </StyledOurPickWrapper>
              )}

              {!ourPick && !sponsored && label && (
                <StyledOurPickWrapper data-testid="shared-products-card-product-tile-pill">
                  <Pill label={label} variant={variant} icon={icon} />
                </StyledOurPickWrapper>
              )}

              <StyledImage aspectRatio={portraitCardAspectRatio} {...image} />
            </Link>
          </StyledImageWrapper>
        </Box>
      </StyledWrapper>
    )
  },
)

CardProductTile.displayName = 'CardProductTile'
