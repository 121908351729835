import { useState } from 'react'
import { useStore } from '@moonpig/web-core-stores'
import { useRouter } from '@moonpig/web-core-routing'
import { CardLineItem } from './useExistingCardOptions'
import { useAddDigitalGiftToBasket } from './useAddDigitalGiftToBasket'
import { Journeys } from './Journeys'
import { ProductInfoProduct } from '../../types'

export const useAddDigitalGiftToExistingCard = () => {
  const { addDigitalGiftToBasket } = useAddDigitalGiftToBasket()
  const [loading, setLoading] = useState(false)
  const {
    store: { id: region },
  } = useStore()
  const router = useRouter()

  const addDigitalGiftToExistingCard = async ({
    basketId,
    digitalGift,
    existingCard,
  }: {
    existingCard: CardLineItem
    basketId: string
    digitalGift: ProductInfoProduct
  }) => {
    setLoading(true)
    try {
      await addDigitalGiftToBasket({
        basketId,
        productMasterVariant: {
          ...digitalGift.masterVariant,
          addons: [],
        },
        region,
        lastLineItem: existingCard,
      })

      router.push({
        name: 'customise-edit',
        params: {
          designId: existingCard.design.id,
          journey: Journeys.EXPERIENCE_GIFT_FIRST_EXISTING,
          region,
          variantKey: '1',
        },
      })
    } finally {
      setLoading(false)
    }
  }
  return { addDigitalGiftToExistingCard, loading }
}
