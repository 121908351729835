import { SelectContentEvent } from '@moonpig/web-core-analytics'

export const createProductQuantitySelectorSelectContentGAEvent = ({
  productId,
  quantity,
  masterVariantTitle,
}: {
  productId: string
  quantity: number
  masterVariantTitle: string
}): SelectContentEvent => {
  const domain = 'product details'

  const title = masterVariantTitle.toLowerCase()
  return {
    event: 'select_content',
    content_data: {
      content_type: `${domain} | select quantity | quantity selector`,
      item_id: `${quantity} | ${productId} | ${title}`,
    },
    event_data: {
      category: domain,
      action: 'select quantity',
      label: `quantity selector | ${productId} | ${title}`,
      non_interaction: true,
      value: undefined,
    },
    discount_data: undefined,
    error_data: undefined,
    ecommerce: undefined,
  }
}
