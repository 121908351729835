/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useLocale } from '@moonpig/web-core-stores'
import { useStripeLoader } from '@moonpig/web-shared-payments'
import { theme } from '@moonpig/launchpad-theme'
import {
  Elements,
  PaymentMethodMessagingElement,
} from '@stripe/react-stripe-js'
import {
  StripeElementLocale,
  StripePaymentMethodMessagingElementOptions,
} from '@stripe/stripe-js'

type Props = {
  price: number
  currency: string
}

export const KlarnaInfo: FC<Props> = ({ price, currency, ...rest }) => {
  const { countryCode } = useLocale()
  const { stripe, error } = useStripeLoader()

  if (error) {
    return <></>
  }

  const appearance = {
    variables: {
      colorText: theme.colors.colorTextBody,
      fontSizeBase: `${theme.typography.typeBodyText.fontSize}`,
      fontFamily: `${theme.typography.typeBodyText.fontFamily}`,
    },
  }

  const elementOptions = {
    appearance,
    locale: countryCode as StripeElementLocale,
  }

  const options = {
    amount: price,
    currency,
    countryCode: countryCode.toUpperCase(),
    paymentMethodTypes: ['klarna' as const],
  } as StripePaymentMethodMessagingElementOptions

  return (
    <Box data-testid="klarna-payment-method-messaging-wrapper" mb={6} {...rest}>
      <Elements
        data-testid="klarna-payment-method-messaging-elements"
        stripe={stripe}
        options={elementOptions}
      >
        <PaymentMethodMessagingElement options={options} />
      </Elements>
    </Box>
  )
}
