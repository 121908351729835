import React, { FC } from 'react'
import { useLanguage } from '@moonpig/web-core-stores'
import { formatPrice } from '@moonpig/web-core-utils'
import { Text } from '@moonpig/launchpad-components'
import { useLocaleText } from './FromPrice.locale'

export const FromPrice: FC<{
  priceCentAmount: number
  currencyCode: string
  fractionDigits: number
  minimumQuantity?: number
}> = ({ priceCentAmount, currencyCode, fractionDigits, minimumQuantity }) => {
  const localise = useLocaleText()
  const language = useLanguage()

  const formattedMasterVariantPrice = formatPrice(
    priceCentAmount,
    fractionDigits,
    currencyCode,
    minimumQuantity,
    language,
  )

  return (
    <Text
      typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' }}
      color={'colorTextAlternate'}
    >
      {localise('from_price.copy')} {formattedMasterVariantPrice}
    </Text>
  )
}
