import { SelectContentEvent } from '@moonpig/web-core-analytics'

export const createSelectAddonGAEvent = ({
  action,
  sku,
  controlType,
}: {
  action: string
  sku: string
  controlType: string
}): SelectContentEvent => ({
  event: 'select_content',
  content_data: {
    content_type: `add-ons | ${action} content | ${controlType}`,
    item_id: sku.toLowerCase(),
  },
  event_data: {
    action: `${action} content`,
    category: 'add ons',
    non_interaction: true,
    label: `${controlType} | ${sku.toLowerCase()}`,
    value: undefined,
  },
  discount_data: undefined,
  error_data: undefined,
  ecommerce: undefined,
})
