export const formatPrice = (
  centAmount: number,
  fractionDigits: number,
  currencyCode: string,
  minimumQuantity = 1,
  locale = 'en',
): string => {
  const decimalPrice = formatPriceAsNumber(
    centAmount,
    fractionDigits,
    minimumQuantity,
  )

  return decimalPrice.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: fractionDigits,
  })
}

export const formatPriceAsNumber = (
  centAmount: number,
  fractionDigits: number,
  minimumQuantity = 1,
): number => {
  return (centAmount * minimumQuantity) / 10 ** fractionDigits
}
