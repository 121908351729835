import { getParentDepartment } from '@moonpig/web-core-utils'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { ViewItemEvent } from '@moonpig/web-core-analytics'
import { getCurrencyCode } from '@moonpig/web-shared-utils'
import { AddonsFieldsFragment } from '../queries/useProductWithAddonsQuery/__generated__/useProductWithAddonsQuery'
import { isSponsoredCheck } from './utils'

export type VariantInfo = {
  addons?: AddonsFieldsFragment[] | null
  price: {
    currencyCode: string
  }
}

type Product = {
  id: string
  category: string
  title: string
  price: number
  index: number
  categoryDepartment?: string
  inStock: boolean
  variantTitle?: string
  isSponsored?: boolean
  internallyPromoted?: boolean
}

type ProductViewItemEventProps = {
  product: Product
  variants: VariantInfo[]
  pageLocation: string
}

type ProductItemProps = {
  products: Product[]
  label: string
  listName: string
  currencyCode: string
  productIndex: number
}

const createViewItemsGAEvent = ({
  products,
  label,
  listName,
  currencyCode,
  productIndex,
}: ProductItemProps): ViewItemEvent => {
  const eventType = 'view_item'
  const value = Number(
    products.reduce((total, product) => total + product.price, 0) / 100,
  )
  return {
    event: eventType,
    ecommerce: {
      currency: getCurrencyCode(currencyCode.toLocaleUpperCase()),
      value,
      items: products.map(product => ({
        index: productIndex,
        item_brand: BRAND_LABEL.toLowerCase(),
        item_category: product.category.toLowerCase(),
        item_category2: product.categoryDepartment
          ? /* istanbul ignore next */ getParentDepartment(
              product.categoryDepartment,
            ).toLowerCase()
          : /* istanbul ignore next */ undefined,
        item_category3: product.inStock
          ? /* istanbul ignore next */ 'in-stock'
          : /* istanbul ignore next */ 'out-of-stock',
        item_category4: undefined,
        item_category5: undefined,
        item_id: product.id.toLowerCase(),
        item_list_name: listName.toLowerCase(),
        item_name: product.title.toLowerCase(),
        item_variant: product?.variantTitle?.toLowerCase() || undefined,
        price: Number(product.price / 100),
        quantity: 1,
        is_sponsored: isSponsoredCheck(
          product.isSponsored,
          product.internallyPromoted,
        ),
      })),
    },
    // For GA3 backward compatibility //
    event_data: {
      category: 'product action',
      action: eventType.replace('_', ' '),
      label: label.toLowerCase(),
      non_interaction: true,
      value: Math.round(value),
    },
  }
}

export const createViewItemsWithAddonsGAEvent = ({
  product,
  variants,
  pageLocation,
}: ProductViewItemEventProps) => {
  const products = [product]

  const allProductAddons = variants
    .map(variant => {
      /* istanbul ignore next */
      return variant.addons
        ? variant.addons.filter(({ inStock }) => inStock)
        : []
    })
    .flat()
    .filter(({ inStock }) => inStock)

  const allUniqueAddons = Array.from(
    new Map(allProductAddons.map(addon => [addon.sku, addon])).values(),
  )

  const addonLabels: string[] = []
  if (allUniqueAddons.length > 0) {
    allUniqueAddons.forEach(productAddon => {
      const { sku, inStock, price, title: addonName } = productAddon
      const sanitizedSku = sku.replace(/_/g, '-')
      addonLabels.push(sanitizedSku)
      products.push({
        id: sanitizedSku,
        title: addonName,
        price: price.centAmount,
        inStock,
        category: product.category,
        index: product.index,
      })
    })
  }

  const listName = pageLocation.trim()
  const label = `${listName} | ${product.id}${
    addonLabels.length
      ? ` + addons: ${addonLabels.join(', ')}`.substring(0, 100)
      : ''
  }`

  return createViewItemsGAEvent({
    products,
    listName,
    label,
    currencyCode: variants[0].price.currencyCode.toLocaleLowerCase(),
    productIndex: product.index + 1,
  })
}
