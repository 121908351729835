import { EventObject, trackGAEvent } from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'

const ADD_GIFT_TO_CARD_CTA_TOKEN = 'common.add_gift_to_a_card' as const
const ADD_TO_CARD_CTA_TOKEN = 'common.add_to_card' as const
const PERSONALISE_CTA_TOKEN = 'common.personalise' as const

export type CanSeeCardOptionsResult = {
  canSeeCardOptions: boolean
  digitalGiftCtaToken:
    | typeof PERSONALISE_CTA_TOKEN
    | typeof ADD_GIFT_TO_CARD_CTA_TOKEN
    | typeof ADD_TO_CARD_CTA_TOKEN
}

export const useDigitalGiftingCardOptions = () => {
  const store = useStoreId()
  const canSeeCardOptions = (
    department: DepartmentsEnum,
  ): CanSeeCardOptionsResult => {
    if (department === DepartmentsEnum.DIGITAL_GIFT_CARDS) {
      return {
        canSeeCardOptions: false,
        digitalGiftCtaToken: ADD_TO_CARD_CTA_TOKEN,
      }
    }
    if (department !== DepartmentsEnum.DIGITAL_GIFTS) {
      return {
        canSeeCardOptions: false,
        digitalGiftCtaToken: PERSONALISE_CTA_TOKEN,
      }
    }

    return {
      canSeeCardOptions: true,
      digitalGiftCtaToken: ADD_GIFT_TO_CARD_CTA_TOKEN,
    }
  }

  const seeCardOptions = ({
    productId,
    gaEvent,
  }: {
    productId: string
    gaEvent: EventObject
  }) => {
    trackGAEvent(gaEvent)
    window.location.assign(`/${store}/card-options/${productId}`)
  }

  return {
    getCanSeeCardOptions: canSeeCardOptions,
    seeCardOptionsForProduct: seeCardOptions,
  }
}
