import React, { FC } from 'react'
import { TextLink, Text } from '@moonpig/launchpad-components'
import { IconSystemHeart } from '@moonpig/launchpad-assets'
import { useStoreId } from '@moonpig/web-core-stores'
import { SelectContentEvent, trackGAEvent } from '@moonpig/web-core-analytics'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useProductsLocaleText } from '../../../locale'

const StyledToastNotification = styled.div`
  position: fixed;
  left: 50%;
  bottom: 24px;
  display: flex;
  align-items: center;
  transform: translate(-50%, 0);
  animation: toastInAndOut 5500ms ease-in-out forwards;
  z-index: 3000;

  ${s({
    width: { xs: '100%', md: 'auto' },
    maxWidth: { xs: 'calc(100vw - 24px)', md: 'auto' },
    bgcolor: 'colorBackgroundInformation',
    color: 'colorTextLabel',
    py: 7,
    px: { xs: 6, md: 8 },
    borderRadius: 2,
    boxShadow: 3,
  })};

  &:hover {
    animation-play-state: paused;
  }

  @keyframes toastInAndOut {
    0% {
      transform: translate(-50%, 200%);
    }
    4% {
      transform: translate(-50%, 0);
    }
    96% {
      transform: translate(-50%, 0);
    }
    100% {
      transform: translate(-50%, 200%);
    }
  }

  @media (prefers-reduced-motion) {
    @keyframes toastInAndOut {
      0% {
        opacity: 0;
      }
      4% {
        opacity: 1;
      }
      96% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`

const StyledLink = styled(TextLink)`
  ${s({
    typography: 'typeButtonLabel',
    ml: 'auto',
  })}
`
const createSelectContentGAEvent = (location: string): SelectContentEvent => ({
  event: 'select_content',
  content_data: {
    content_type: `${location} | view favourites | favourite toast`,
  },
  // For GA3 backward compatibility
  event_data: {
    category: location,
    action: 'view favourites',
    label: 'favourite toast',
    non_interaction: true,
    value: undefined,
  },
  discount_data: undefined,
  ecommerce: undefined,
  error_data: undefined,
})

export const ToastNotification: FC<{
  tracking: { location: string }
  onAnimationEnd: () => void
}> = ({ tracking: { location }, onAnimationEnd }) => {
  const storId = useStoreId()
  const localise = useProductsLocaleText()

  const selectContentGAEvent = createSelectContentGAEvent(location)

  return (
    <StyledToastNotification
      data-testid="toast-notification"
      onAnimationEnd={() => onAnimationEnd()}
    >
      <IconSystemHeart width="24px" height="24px" />
      <Text as="span" typography="typeBodyLabel" ml={4} mr={{ xs: 4, md: 11 }}>
        {localise('favourites.item_added_to_your_favourites')}
      </Text>
      <StyledLink
        href={`/${storId}/account/favourites/`}
        onClick={() => trackGAEvent(selectContentGAEvent)}
      >
        {localise('favourites.view')}
      </StyledLink>
    </StyledToastNotification>
  )
}
