export const STANDARD_CARD_SIZE_ID = '1'
export const LARGE_CARD_SIZE_ID = '5'
export const GIANT_CARD_SIZE_ID = '133'

export const SQUARE_CARD_SIZE_ID = '13'
export const LARGE_SQUARE_CARD_SIZE_ID = '79'
export const GIANT_SQUARE_CARD_SIZE_ID = '137'

export const E_CARD_SIZE_ID = '135'
export const POSTCARD_SIZE_ID = '14'

export const STANDARD_FLOWER_SIZE_ID = '18'
export const LARGE_FLOWER_SIZE_ID = '23'

export const DEFAULT_REQUIRES_EDITING = true
