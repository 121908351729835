import { Region } from '@moonpig/web-core-types'
import {
  createBasketEvent,
  createCustomisationCompletedEvent,
  trackGAEvent,
  trackUIEvent,
  UIEvent,
} from '@moonpig/web-core-analytics'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { getDeviceType } from '@moonpig/web-shared-utils'
import { MasterVariant } from './types'
import { createPersonaliseGAEvent } from '../../analytics'
import { Journeys } from './Journeys'

const gracefullyTrackUIEvent = async (eventData: UIEvent): Promise<void> => {
  try {
    await trackUIEvent(eventData)
  } catch (error) {
    // @eslint-disable-next-line @typescript-eslint/no-empty
  }
}

export const trackAddCardAndDigitalGiftToBasket = async ({
  digitalGiftProductMasterVariant,
  cardSku,
  cardId,
  designId,
  basketId,
  totalBasketItems,
  cardVariant,
  componentName,
  journey,
  productId,
  region,
  customerId,
}: {
  digitalGiftProductMasterVariant: MasterVariant
  cardSku: string
  cardId: string
  designId: string
  basketId: string
  totalBasketItems: number
  cardVariant?: string
  componentName: string
  journey: Journeys
  productId: string
  region: Region
  customerId?: string
}) => {
  const price = digitalGiftProductMasterVariant.price.centAmount / 100
  const formattedPrice = price.toFixed(2)
  const item = {
    item_brand: 'moonpig',
    item_name: digitalGiftProductMasterVariant.title.toLocaleLowerCase(),
    item_id:
      digitalGiftProductMasterVariant.sku ?? /* istanbul ignore next */ '',
    item_variant: undefined,
    item_category: DepartmentsEnum.DIGITAL_GIFTS.toLocaleLowerCase().replace(
      '_',
      ' ',
    ),
    item_category2: 'all_gifts',
    item_category3: 'in-stock',
    item_category4: 'non-group-card',
    item_category5: undefined,
    item_list_name: undefined,
    index: undefined,
    quantity: 1,
    price,
  }

  if (journey !== Journeys.EXPERIENCE_GIFT_FIRST_UPGRADED) {
    trackGAEvent(
      createPersonaliseGAEvent({
        componentName,
        productData: {
          id: cardId,
          category: DepartmentsEnum.GREETING_CARDS.toLocaleLowerCase().replace(
            '_',
            ' ',
          ),
          design_id: designId,
          quantity: 1,
          variant: cardVariant,
          name: cardSku,
        },
      }),
    )
  }

  trackGAEvent(
    createBasketEvent({
      event: 'add_to_cart',
      products: [item],
      basket: {
        id: basketId,
        size: totalBasketItems,
        action: 'add to cart',
      },
      action: 'add to basket',
      currency:
        digitalGiftProductMasterVariant.price.currencyCode.toLocaleLowerCase(),
      totalValue: Number(formattedPrice),
      label: `digital gift | ${digitalGiftProductMasterVariant.sku}`,
      productIndex: undefined,
      eventCategory: 'product action',
    }),
  )

  const { mnpg_ui_events_api_correlation_id: correlationId } =
    getBrowserCookies()

  const customisationCompletedEvent = createCustomisationCompletedEvent({
    correlationId,
    productId,
    store: region.toUpperCase(),
    source: getDeviceType(),
    customerId,
  })

  await gracefullyTrackUIEvent(customisationCompletedEvent)
}
