import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useLanguage } from '@moonpig/web-core-stores'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { formatProductVariants } from './formatProductInfoVariants'
import { formatVariantCategories } from './formatProductInfoVariantCategories'
import { mapImagesToCarouselItems } from './mapImagesToCarouselItems'
import { MoreInfoLink } from './MoreInfoLink'
import { E_CARD_SIZE_ID, POSTCARD_SIZE_ID } from '../../constants'
import {
  AddedToBasketEvent,
  ButtonActionName,
  ProductInfoProduct,
  ProductInfoTrackingData,
  ProductItemEventEnum,
  UpsellProduct,
  RequiresEditing,
  DispatchCutOff,
} from '../../types'
import { getProductImageDimensions } from '../../utils/getProductImageDimensions'
import { DispatchCutOffMessage } from '../DispatchCutOffMessage'
import { ProductTabs } from '../ProductTabs'
import { ProductQuickView } from '../ProductQuickView'
import {
  createProductItemGAEvent,
  createProductQuantitySelectorSelectContentGAEvent,
} from '../../analytics'
import { useDigitalGiftingCardOptions } from '../../utils/useDigitalGiftingCardOptions/useDigitalGiftingCardOptions'
import { useProductInfoLocaleText } from './ProductInfo.locale'
import { TitleVariants, variantInfoText } from './variantInfoText'
import { useFavouritesState, checkFavourited } from '../../contexts/favourites'
import { useHandleFavourite } from '../../utils/favourites'
import { useProductStore } from '../../contexts/product'
import { isCardProduct } from '../../utils/isCardProduct'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

const TSHIRT_CATEGORY_ID = 12

type ProductQuickViewProps = React.ComponentProps<typeof ProductQuickView>
type WidthProps = Pick<ProductQuickViewProps, 'leftWidth' | 'rightWidth'>

export type ProductInfoProps = WidthProps & {
  product: ProductInfoProduct & {
    frequentlyBoughtTogether?: { products: (ProductInfoProduct | null)[] }
  }
  productIndex?: number
  onAddToBasket: (addedToBasketEvent: AddedToBasketEvent) => void
  onVariantChange?: (variantValue: string) => void
  showTitle?: boolean
  showMoreInfo?: boolean
  dispatchCutOff: DispatchCutOff | null
  showProductTabs?: boolean
  hideStickyCta?: boolean
  pageLocation: string
  groupCardProject?: string
  requiresEditing?: RequiresEditing
  addonLoaded?: boolean
  isFromModal?: boolean
  disableQuantitySelector?: boolean
  upsellProduct?: UpsellProduct
  onPeopleAlsoViewedProductChange?: (peopleAlsoViewedProductId: string) => void
}

const {
  variantsTitleLookup,
  variantCategoriesTitleLookup,
  variantInfoLinkLookup,
  variantInfoLinkTextLookup,
  infoAlertTitleLookup,
  infoAlertTextLookup,
} = variantInfoText

export const ProductInfo: FC<ProductInfoProps> = ({
  product: initialProduct,
  productIndex = 0,
  onAddToBasket,
  onVariantChange = () => {},
  leftWidth,
  rightWidth,
  showTitle = true,
  showMoreInfo = false,
  dispatchCutOff,
  showProductTabs = false,
  hideStickyCta,
  pageLocation,
  groupCardProject,
  requiresEditing,
  addonLoaded,
  isFromModal,
  disableQuantitySelector,
  upsellProduct,
}) => {
  const [{ product: productState, variant, quantity }, productStoreDispatcher] =
    useProductStore()

  const { favourites } = useFavouritesState()

  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: 1,
      pageLocation,
    },
    removeWithConfirmation: false,
  })

  const isInitialProductActive = productState.id === initialProduct.id
  const [quantityWasChangedManually, setQuantityWasChangedManually] =
    useState(false)
  const [addonsLoadedAndFormatted, setAddonsLoadedAndFormatted] = useState(
    addonLoaded === undefined,
  )
  const localiseText = useProductInfoLocaleText()
  const language = useLanguage()

  const getLocalized = (lookup: TitleVariants): string | undefined => {
    const localizationKey =
      lookup[initialProduct.category.id as keyof typeof lookup]
    if (localizationKey) {
      return localiseText(localizationKey)
    }
    return undefined
  }

  const variantsTitle = getLocalized(variantsTitleLookup)

  const variantCategoriesTitle = getLocalized(variantCategoriesTitleLookup)

  const variantInfoLink =
    variantInfoLinkLookup[
      initialProduct.category.id as keyof typeof variantInfoLinkLookup
    ]

  const variantInfoLinkText = getLocalized(variantInfoLinkTextLookup)

  const infoAlertTitle = getLocalized(infoAlertTitleLookup)

  const infoAlertText = getLocalized(infoAlertTextLookup)

  useEffect(() => {
    if (quantity > 1) {
      setQuantityWasChangedManually(true)
    }
  }, [quantity])

  useEffect(() => {
    if (quantityWasChangedManually) {
      trackGAEvent(
        createProductQuantitySelectorSelectContentGAEvent({
          productId: productState.id,
          quantity,
          masterVariantTitle: initialProduct.masterVariant.title,
        }),
      )
    }
  }, [
    quantity,
    quantityWasChangedManually,
    pageLocation,
    initialProduct,
    productState.id,
  ])

  useEffect(() => {
    if (addonsLoadedAndFormatted) return
    if (addonLoaded) {
      setAddonsLoadedAndFormatted(true)
    }
  }, [addonLoaded, addonsLoadedAndFormatted])

  useEffect(() => {
    /* istanbul ignore next */
    const doesProductHaveAddons = (stateLocation: ProductInfoProduct) =>
      stateLocation.variants.find(v => v?.addons?.length)
    if (
      (isInitialProductActive &&
        !doesProductHaveAddons(productState) &&
        doesProductHaveAddons(initialProduct)) ||
      (isInitialProductActive && productState.id !== initialProduct.id)
    ) {
      productStoreDispatcher.setProduct(initialProduct)
    }
  }, [
    isInitialProductActive,
    initialProduct,
    productState,
    productStoreDispatcher,
  ])

  const imageDimensions = getProductImageDimensions(
    isCardProduct(productState.category.department),
    productState.masterVariant.key,
    productState.isLandscape,
  )

  const carouselItems: JSX.Element[] = mapImagesToCarouselItems({
    images: productState.masterVariant.images,
    imageDimensions,
    isCardProduct: isCardProduct(productState.category.department),
    isLandscape: productState.isLandscape,
    isPostcard: productState.masterVariant.key === POSTCARD_SIZE_ID,
    productSizeId: variant && variant.key,
  })

  const eCardDescription = localiseText('common.sent_instantly_via_email')

  const formattedVariants = formatProductVariants({
    variants: productState.variants,
    selectedVariant: variant,
    id: productState.id,
    language,
    eCardDescription,
  })

  const variantCategories =
    productState.category.id === TSHIRT_CATEGORY_ID
      ? formatVariantCategories(formattedVariants, productState.id)
      : undefined

  const variantChangeCallback = useCallback(
    (variantId: string) => {
      if (variantId === E_CARD_SIZE_ID) {
        productStoreDispatcher.selectQuantity(1)
      }

      productStoreDispatcher.selectVariant(variantId)
      onVariantChange(variantId)
    },
    [onVariantChange, productStoreDispatcher],
  )

  const getProductTitle = () => {
    if (
      isCardProduct(productState.category.department) &&
      formattedVariants.length === 1
    ) {
      return formattedVariants[0].label
    }
    return showTitle ? productState.title : ''
  }

  const [showDispatchCutOff, setShowDispatchCutOff] = useState(true)

  const { getCanSeeCardOptions, seeCardOptionsForProduct } =
    useDigitalGiftingCardOptions()
  const hideDispatchCutOff = useCallback(() => {
    setShowDispatchCutOff(false)
  }, [])

  const isProductCustomisable =
    productState.customisable ||
    initialProduct.category.department === DepartmentsEnum.DIGITAL_GIFTS

  const trackingData: ProductInfoTrackingData = {
    pageLocation,
    product: {
      id: productState.id,
      title: productState.title,
      price: productState.masterVariant.price.centAmount,
      variantTitle: productState.masterVariant.title,
      slug: productState.slug,
      categoryName: productState.category.name,
      categorySlug: productState.category.slug,
      index: productIndex,
      addonLoaded: addonsLoadedAndFormatted,
      inStock: productState.masterVariant.inStock,
      categoryDepartment: productState.category.department,
    },
  }

  const { canSeeCardOptions, digitalGiftCtaToken } = getCanSeeCardOptions(
    initialProduct.category.department,
  )
  const getAddToBasketText = (
    isCustomisable: boolean,
    groupCardProjectId: string | undefined,
  ): string => {
    if (canSeeCardOptions) {
      return localiseText(digitalGiftCtaToken)
    }

    if (isCustomisable) {
      return groupCardProjectId
        ? localiseText('common.next')
        : localiseText('common.personalise')
    }

    return localiseText('common.add_to_basket')
  }

  const onAddToBasketAction = (
    selectedAddon: AddonsFieldsFragment | null,
    buttonActionName?: ButtonActionName,
    requiresEditingValue?: boolean,
  ) => {
    if (canSeeCardOptions) {
      const gaEvent = createProductItemGAEvent({
        eventType: ProductItemEventEnum.SELECT_ITEM,
        product: initialProduct,
        variant: initialProduct.masterVariant.title,
        index: productIndex,
        listName: '',
        label: '',
      })
      seeCardOptionsForProduct({
        productId: initialProduct.id,
        gaEvent,
      })
      return
    }

    onAddToBasket({
      product: productState,
      productIndex,
      variant,
      selectedAddon,
      quantity,
      buttonActionName,
      requiresEditingValue,
    })
  }

  return (
    <>
      <ProductQuickView
        id={productState.id}
        leftWidth={leftWidth}
        rightWidth={rightWidth}
        titleAs="h1"
        title={getProductTitle()}
        carouselItems={carouselItems}
        productImages={productState.masterVariant.images.map(({ url }) => url)}
        onAddToBasket={onAddToBasketAction}
        moreInfoLinkElement={
          showMoreInfo ? (
            <MoreInfoLink
              categorySlug={productState.category.slug}
              slug={productState.slug}
              productId={productState.id}
              variantKey={variant.key}
              groupCardProject={groupCardProject}
            />
          ) : undefined
        }
        rating={
          isCardProduct(productState.category.department)
            ? undefined
            : productState.rating || undefined
        }
        addToBasketText={getAddToBasketText(
          isProductCustomisable,
          groupCardProject,
        )}
        enableStickyCta
        onVariantChange={variantChangeCallback}
        variantsTitle={variantsTitle}
        variants={formattedVariants}
        variantCategories={variantCategories}
        variantCategoriesTitle={variantCategoriesTitle}
        variantInfoLink={variantInfoLink}
        variantInfoLinkText={variantInfoLinkText}
        infoAlertTitle={infoAlertTitle}
        infoAlertText={infoAlertText}
        quantity={quantity}
        onQuantityChange={productStoreDispatcher.selectQuantity}
        imageDimensions={imageDimensions}
        data-testid="product-quick-view"
        dispatchTimeMessage={
          showDispatchCutOff && dispatchCutOff ? (
            <DispatchCutOffMessage
              dispatchCutOff={dispatchCutOff}
              hideDispatchCutOff={hideDispatchCutOff}
            />
          ) : undefined
        }
        isLandscape={!!productState.isLandscape}
        hideStickyCta={hideStickyCta}
        isCardProduct={isCardProduct(productState.category.department)}
        trackingData={trackingData}
        requiresEditing={requiresEditing}
        groupCardProject={groupCardProject}
        isProductCustomisable={isProductCustomisable}
        primaryProductPill={productState.primaryProductPill}
        productPills={productState.productPills}
        isFromModal={isFromModal}
        disableQuantitySelector={disableQuantitySelector}
        upsellProduct={
          productState.id === upsellProduct?.id ? undefined : upsellProduct
        }
        onUpsellProductClick={event => {
          if (isFromModal) {
            event.preventDefault()
            productStoreDispatcher.setProduct(upsellProduct as UpsellProduct)
          }
        }}
        isFavourited={checkFavourited(favourites, productState)}
        onFavouriteToggle={() => {
          handleFavourite(
            productState,
            checkFavourited(favourites, productState),
            productIndex,
          )
        }}
        isSponsored={productState.isSponsored}
        internallyPromoted={productState.internallyPromoted}
      />
      {showProductTabs && (
        <Box mt={8}>
          <ProductTabs
            description={productState.description as string}
            productId={productState.id}
            department={productState.category.department as DepartmentsEnum}
            rating={productState.rating || null}
          />
        </Box>
      )}
    </>
  )
}
