export const isSponsoredCheck = (
  productIsSponsored?: boolean,
  productIsInternallyPromoted?: boolean,
) => {
  if (productIsSponsored) {
    return 'true'
  }

  if (productIsInternallyPromoted) {
    return 'internal'
  }

  return 'false'
}

type CurrencyCode = 'AUD' | 'GBP' | 'EUR' | 'USD'

export const currencyCode = (currencyCodeString: string): CurrencyCode => {
  const supportedCurrencyCodes = ['AUD', 'GBP', 'EUR', 'USD']

  if (supportedCurrencyCodes.includes(currencyCodeString)) {
    return currencyCodeString as CurrencyCode
  }
  return 'GBP'
}
