import React, { FC, useEffect, useState } from 'react'
import { AllSystemProps } from '@moonpig/launchpad-system'
import { useExperiment } from '@moonpig/web-core-experiments'
import { PAYMENT_METHODS } from '@moonpig/web-core-brand/config'
import { useStoreId } from '@moonpig/web-core-stores'
import { Region } from '@moonpig/web-core-types'
import { useProductStore } from '../../contexts/product'
import { KlarnaInfo } from '../../components/KlarnaInfo'

type isKlarnaEnabledParams = {
  centAmount: number
  fractionDigits: number
  storeId: Region
}

const isKlarnaEnabled = ({
  centAmount,
  fractionDigits,
  storeId,
}: isKlarnaEnabledParams) => {
  const klarnaMinimalPrice = 20
  const isOrderAboveThreshold =
    centAmount / 10 ** fractionDigits >= klarnaMinimalPrice
  return isOrderAboveThreshold && PAYMENT_METHODS[storeId]?.includes('Klarna')
}

export const KlarnaInfoModule: FC<AllSystemProps> = () => {
  const [
    {
      product: {
        masterVariant: {
          price: {
            centAmount: masterCentAmount,
            fractionDigits: masterFractionDigits,
            currencyCode: masterCurrencyCode,
          },
        },
      },
      variant: {
        price: {
          centAmount: selectedCentAmount,
          fractionDigits: selectedFractionDigits,
          currencyCode: selectedCurrencyCode,
        },
      },
    },
  ] = useProductStore()

  const [centAmount, setCentAmount] = useState(masterCentAmount)
  const [currencyCode, setCurrencyCode] = useState(masterCurrencyCode)
  const [fractionDigits, setFractionDigits] = useState(masterFractionDigits)

  useEffect(() => {
    setCentAmount(selectedCentAmount)
    setCurrencyCode(selectedCurrencyCode)
    setFractionDigits(selectedFractionDigits)
  }, [selectedCentAmount, selectedCurrencyCode, selectedFractionDigits])

  const isExperimentEnabled =
    useExperiment('explore-show-klarna')?.toLowerCase() === 'enabled'

  const storeId = useStoreId()

  if (
    isExperimentEnabled &&
    isKlarnaEnabled({ centAmount, fractionDigits, storeId })
  ) {
    return <KlarnaInfo price={centAmount} currency={currencyCode} />
  }

  return <></>
}
