import {
  Box,
  Pill,
  Modal,
  Heading,
  IconButton,
  Text,
} from '@moonpig/launchpad-components'
import { IconInformation } from '@moonpig/launchpad-assets'
import React, { FC, createRef, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { useProductsLocaleText } from '../../locale'
import { InfoPillButton } from './InfoPillButton'

const StyledButton = styled(IconButton)`
  ${s({
    color: 'colorTextHeadline',
  })};
`
const StyledSponsoredPill = styled(Pill)`
  ${s({
    position: 'absolute',
    top: 8,
    borderRadius: '20px',
    maxHeight: '28px',
    bgcolor: 'colorBlack20',
    color: 'colorTextHeadline',
    zIndex: 4,
  })}
`

export const SponsoredPill: FC = () => {
  const t = useProductsLocaleText()
  const [open, setOpen] = useState(false)
  const modalTriggerRef = createRef<HTMLButtonElement>()

  const showCardProductTileVariant =
    useExperimentString('search-product-tile-ux', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }

  return (
    <>
      {showCardProductTileVariant ? (
        <StyledSponsoredPill
          aria-label={'sponsored product tile'}
          label={t('sponsored_pill.pill_label')}
          variant={'sponsored'}
          buttonIcon={
            <InfoPillButton
              onClick={handleOnClick}
              ariaLabel="sponsored product information"
              ref={modalTriggerRef}
            />
          }
        />
      ) : (
        <Pill
          aria-label={'sponsored product tile'}
          label={t('sponsored_pill.pill_label')}
          variant={'sponsored'}
          buttonIcon={
            <StyledButton
              onClick={handleOnClick}
              color="colorFeedbackInformation"
              icon={IconInformation}
              label={t('sponsored_pill.pill_label')}
              aria-label={'sponsored product information'}
              ref={modalTriggerRef}
            />
          }
        />
      )}

      <Modal
        label={t('sponsored_pill.modal_label')}
        isOpen={open}
        onDismiss={() => setOpen(false)}
        hasCloseButton
        triggerRef={modalTriggerRef}
      >
        <Heading level="h2" mb={6} textAlign="left" tabIndex={0}>
          {t('sponsored_pill.modal_label')}
        </Heading>
        <Box textAlign="left" width="700px">
          <Text as={'p'} tabIndex={0}>
            {t('sponsored_pill.modal_content')}
          </Text>
        </Box>
      </Modal>
    </>
  )
}
