import { fetch } from '@moonpig/web-core-fetch'
import { getApiUrl } from '@moonpig/web-core-utils'
import { UIEvent } from './types'

export const trackUIEvent = async ({ event: type, ...rest }: UIEvent) => {
  const url = `${getApiUrl()}/ui-events`

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      eventDate: new Date().getTime(),
      store: 'UK',
      ...rest,
    }),
  })
}
